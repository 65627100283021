/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // slick basic
        $('.slider').slick({
          autoplay: true,
          dots: true,
	        arrows: false,
	        appendDots: $('#dots .dots-holder'),
	        slidesToShow: 1,
          autoplaySpeed: 99999
        });


	    $('.slider-twoup').slick({
		    autoplay: true,
		    dots: false,
		    autoplaySpeed: 99999,
		    slidesToShow: 1,
		    slidesToScroll: 1,
		    prevArrow: $('.prev'),
		    nextArrow: $('.next')
	    });

	      $('.slider-services').slick({
		      autoplay: true,
		      dots: false,
		      autoplaySpeed: 99999,
		      slidesToShow: 1,
		      slidesToScroll: 1,
		      prevArrow: $('.prev'),
		      nextArrow: $('.next')

	      });

	      $('.slider-services').on('afterChange', function(event, slick, currentSlide, nextSlide){
	      	$( '.nav-item' ).removeClass( 'active' );
		      var activeNum = $(slick.$slides.get(currentSlide)).attr('data-slick-index');
		      activeNum = Number(activeNum)+1;
		      var activeSlide = '#slide-'+activeNum;
		      $( activeSlide ).addClass( 'active' );
	      });

	      $('a[data-slide]').click(function(e) {
		      e.preventDefault();
		      var slideno = $(this).data('slide');
		      $('.slider-services').slick('slickGoTo', slideno - 1);
	      });

	      $('.slider-services').slick('slickGoTo', 1);

	      $('.slider-team').slick({
		      autoplay: true,
		      dots: false,
		      autoplaySpeed: 99999,
		      slidesToShow: 1,
		      slidesToScroll: 1,
		      prevArrow: $('.prev'),
		      nextArrow: $('.next')
	      });

			var screenWidth = $( window ).width();
	      $(window).scroll(function () {
		      screenWidth = $( window ).width();
		      if(screenWidth > 750){ ///361
			      if ($(".navbar").offset().top > 100) {
				      $(".navbar-fixed-top").addClass("top-nav-collapse");

			      } else {
				      $(".navbar-fixed-top").removeClass("top-nav-collapse");
			      }
		      }
	      });

	      // stop video playback on modal close
        // http://stackoverflow.com/a/25069916
        $(".video-modal").on('hidden.bs.modal', function (e) {
          var id = $(this).attr('id');
          var target = "#" + id + ".video-modal iframe";
          $(target).attr("src", $(target).attr("src"));
        });

	      /**********************************************
	       * ********************************************
	       * ******* Equal heights
	       * ********************************************
	       */

	      ///// Equalize height in rows
	      var equalheight = function (container, minsize) {
		      var currentTallest = 0,
				      currentRowStart = 0,
				      rowDivs = [],
				      $el,
				      $currentDiv,
				      topPosition = 0;
		      $(container).each(function () {
			      screenWidth = $( window ).width();
			      var classes = $(container).attr('class').split(' ');
			      for(var i = 0; i < classes.length; i++) {
				      //console.log(classes[i]);
			      }
			      $el = $(this);
			      $($el).height('auto');

			      if(screenWidth > minsize){
				      topPostion = $el.position().top;
				      if (currentRowStart !== topPostion) {
					      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
						      rowDivs[currentDiv].height(currentTallest);
					      }
					      rowDivs.length = 0; // empty the array
					      currentRowStart = topPostion;
					      currentTallest = $el.height();
					      rowDivs.push($el);
				      } else {
					      rowDivs.push($el);
					      currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
				      }
				      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
					      rowDivs[currentDiv].height(currentTallest);
				      }
			      }
		      });
	      };
	      ////// end equalheight();
        function wrappedResize(parentClasses, elemClass) {
          var capHeight = 0;
          $(elemClass).css({height: 'auto'});
          if(!$('.navbar-toggle').is(':visible')){

            $(parentClasses).each(function(e){
              capHeight = 0;
              $(elemClass,$(this)).each(function(e){
                if($(this).outerHeight() > capHeight) {
                  capHeight = $(this).outerHeight();
                }
              });
              $(elemClass,$(this)).css({height: capHeight});
            });
          }
        }
	      ////////////////////////////////
	      ////////////////////////////////

	      //// general equalize heights call after load fallback
	      // function equalizeHeights(){
		   //    equalheight('.equals', 767);
	      // }
	      // setTimeout(function(){ equalizeHeights(); }, 500);

        function resizeFuntions() {
          wrappedResize('.home-section, .blog, .card-repeater','.card .thumbnail');
          wrappedResize('.repeater-tabs','.tablinks');
	        wrappedResize( '.team-section','.team-holder' );
	        wrappedResize( '.cards-wrapper','.box-card .box-thumbnail' );
	        wrappedResize( '.careers-list-section','.career-card .card-inner .group-bl' );
	        wrappedResize( '.entry-group','.entry-inner' );
	        wrappedResize( '.news-events-section .container ','.col-md-4' );
	        //wrappedResize( '.news-events-section .container ','.col-md-3' );
	        //wrappedResize( '.news-events-section .container .col-md-4','.ncm-card' );
	        wrappedResize( '.search-group','.search-result' );
	        wrappedResize( '.row','.ncm-card' );
	        wrappedResize( '.card-holder','.ncm-card' );
	        wrappedResize( '.row','.col-sm-4' );
	        wrappedResize( '.row','.col-sm-3' );
	        wrappedResize( '.row','.card-holder' );
	        wrappedResize( '.row2','.ncm-card' );
	        wrappedResize( '.slide-item', '.match-sides' );
	        wrappedResize( '.footer-wrapper', '.footer-nav-holder' );

	        setTimeout(function(){ equalheight( '.team-holder', 768 ); }, 500);
	        setTimeout(function(){ wrappedResize( '.container','.ncm-card' ); }, 500);

        }

	      $('.dropdown').mouseover(function(e) {
		      $(this).addClass('open');
	      });
	      $('.dropdown').mouseout(function(e) {
		      $(this).removeClass('open');
	      });

        $('.dropdown>.caret-wrapper').click(function(e) {
          e.stopPropagation();
          $(this).parent().toggleClass('mobile-open');
          $(this).toggleClass('flipped');
        });

        $('.no-link > a').click(function(e) {
          e.preventDefault();
        });

        setTimeout(function(){
          resizeFuntions();
        }, 1100);

        $(window).resize(function() {
          resizeFuntions();
	        //equalizeHeights();
        });

	      if( screenWidth > 1020 ){
		      $('.twoup-section').height( 400 );
	      }

	      /**********************************************
	       * ********************************************
	       * ******* Basic Tabs
	       * ********************************************
	       */
	      ///// modified to handle multiple tabgroups on a single page
	      function openTab(evt, tabName, tabGroup) {
		      // variables
		      var i, tabcontent, tablinks;

		      tabGroup = '#' + tabGroup;
		      jQuery(tabGroup).find('.tabcontent').removeClass('active');
		      jQuery(tabGroup).find('.tablinks').removeClass('active');
		      jQuery(tabGroup).find('.red-line').removeClass('active');

		      // Show the current tab, and add an "active" class to the button that opened the tab
		      var compTab = tabGroup + ' #' + tabName + '-Tab';
		      jQuery(compTab).addClass('active');
		      var compTabContent = tabGroup + ' #' + tabName;
		      jQuery(compTabContent).addClass('active');
		      jQuery(compTabContent).next().addClass('active');
	      }

	      $('.tablinks').click(function() {
		      var val = $(this).attr('id');
		      var par = $(this).parent().parent().parent().attr('id'); //// pass the parent to set scope
		      openTab(event, val, par);
	      });

	      ////// end openTab() and calls;
	      //////////////////////////////////
	      //////////////////////////////////



	      if ($(".dropdown-menu > .active ")[0]){
		      $( ".dropdown-menu .active" ).last().addClass('actual-page');
	      } else {
		      // Do something if class does not exist
	      }

	      $('#reset-button').click(function() {
	      	var fullPath = window.location.pathname;
		      if( fullPath.includes("library") ){
			      window.location = '/category/library/';
		      }else{
			      window.location = '/category/blog/';
		      }
	      });

	      if ($("body.library")[0]){
		      $( '#disqus_thread' ).remove();
	      }

	      if ($("section#comments")[0]){
		      $( 'section#comments' ).remove();
	      }

	      //// hide the cookie notice and record a session variable that can move into persistent cookie
	      $('.cookie-close-btn').click(function(e){
		      e.preventDefault();
		      document.cookie = "cookie-notice=True;path=/";
		      $('#cookie-message').animate({
			      opacity: 0
		      }, 1500, function() {
			      $('#cookie-message').remove();
		      });
	      });

	      function getCookie(name) {
		      // Split cookie string and get all individual name=value pairs in an array
		      var cookieArr = document.cookie.split(";");

		      // Loop through the array elements
		      for(var i = 0; i < cookieArr.length; i++) {
			      var cookiePair = cookieArr[i].split("=");

			      /* Removing whitespace at the beginning of the cookie name
			       and compare it with the given string */
			      if(name == cookiePair[0].trim()) {
				      // Decode the cookie value and return
				      return decodeURIComponent(cookiePair[1]);
			      }
		      }

		      // Return null if not found
		      return null;
	      }

	      function checkCookie() {
		      // Get cookie using our custom function
		      var showCookie = getCookie("cookie-notice");
		      if( showCookie == "True" ){
		      	// don't show
		      }else{
		      	$( '#cookie-message' ).css( 'display','block' );
		      }
	      }

	      window.addEventListener("load", function(){
		      checkCookie();
	      });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

	        varSliderNavHeight = $( '#slider-nav' ).height();
			$( '.words-inner' ).css( 'top', varSliderNavHeight - 30 );


      }
    },
    // Team /
    'team': {
      init: function() {
        // JavaScript to be fired on the about us page

	      $('#teamX').on('change', function() {
		      var f = document.getElementById("teamX");
		      var fHash = f.options[f.selectedIndex].value;
		      location.hash = fHash; //comboHash;
	      });/// end filter button

	      function changeHash() {
		      var getHash = location.hash;
		      getHash = getHash.substring(1, getHash.length);
		     $("#teamX").val(getHash);

			      var varTeam = getHash;
			      $('#teamX').find('option').each(function(index,element){
				      var testAgainst = element.text;
				      if( varTeam == testAgainst ){
					      document.getElementById("teamX").value = varTeam;
					      matched = true;
				      }
			      });
		      if(getHash){
			      getHash = '.' + getHash; //// convert hash to class name
		      }else{

		      }

		      $('.team-section').isotope({filter: getHash});

		      if ($( getHash ).length > 0) {
			      //if ($(".card").hasClass(getHash)){
			      $("#no-result").text( '');
			      //console.log( '-------- clear no results text -----');
		      }else{
			      $("#no-result").text( 'Sorry there are no results.');
			      //console.log( '---- sorry no results ----');
		      }
		      if( !location.hash ){
			      $("#no-result").text( '');
			      //console.log( '-------- CATCH clear no results text -----');
		      }
	      }

	      window.onhashchange = changeHash;

	      // //// on first load
	      if (location.hash !== '') {
		      changeHash();

	      } else {

	      }


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.